<template>
  <div>
    <LiefengModal
        title="修改菜单"
        :value="controlForm.formFlag"
        :fullscreen="false"
        :width="600"
        @input="closeModal"
        height="calc(100vh - 100px)"
    >
      <template v-slot:contentarea>
        <Form
            :model="formData"
            :label-colon="true"
            :label-width="120"
            :rules="formDataValidate"
            ref="form"
        >

          <FormItem label="菜单名称" prop="name">
            <Input style="width:300px" v-model.trim="formData.name" :maxlength="50"
                   placeholder="请填写菜单名称"></Input>
          </FormItem>
          <FormItem label="菜单路径地址" prop="url">
            <Input style="width:300px" v-model.trim="formData.url" :maxlength="50"
                   placeholder="请填写菜单路径地址"></Input>
          </FormItem>
          <FormItem label="排序" prop="seq">
            <Input style="width:300px" v-model.trim="formData.seq" :maxlength="50"
                   placeholder="请填写排序"></Input>
          </FormItem>
          <FormItem label="是否第三方链接" prop="third">
            <i-switch true-value="2" false-value="1" v-model="formData.third"/>
          </FormItem>
          <FormItem label="启用状态" prop="enableStatus">
            <i-switch true-value="1" false-value="2" v-model="formData.enableStatus"/>
          </FormItem>
          <FormItem label="菜单类别" prop="type">
            <Select v-model="formData.type" style="width:200px;" placeholder="菜单类别">
              <Option v-for="(item,index) in controlForm.menuTypeList" :value="item.typeValue" :key="index">{{
                  item.typeName
                }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="链接目标" prop="linkTarget">
            <Select v-model="formData.linkTarget" style="width:200px;" placeholder="链接目标">
              <Option v-for="(item,index) in controlForm.linkTargetList" :value="item.linkValue" :key="index">{{
                  item.linkName
                }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="跳转方式" prop="jumpType">
            <Select v-model="formData.jumpType" style="width:200px;" placeholder="跳转方式">
              <Option v-for="(item,index) in controlForm.jumpTypeList" :value="item.jumpValue" :key="index">{{
                  item.jumpName
                }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="栏目ID" prop="sysColumnId" v-if="showCascader">
            <Cascader
                style="margin-right: 10px;width:200px"
                v-model="sysColumnId"
                :data="columnList"
                change-on-select
            />
          </FormItem>
          <FormItem label="栏目ID" v-if="showCascader == false">
            <Tag closable @on-close="handleClose">{{formData.columnName || '未知'}}</Tag>
          </FormItem>
          <FormItem label="菜单图标" prop="icon">
            <span slot="label">菜单图标上传:</span>
            <LiefengUpload ref="LiefengUploadIcon" v-if="showImage" accept=".jpg,.png,.gif,.jpeg" :format="['jpg', 'png', 'jpeg', 'gif']" :defaultList='controlForm.defaultListIcon' :showView="true"></LiefengUpload>
          </FormItem>
          <FormItem label="配置JSON" prop="configJson">
            <Input type="textarea" rows="5" v-model.trim="formData.configJson" style="width:300px" placeholder="请填写配置JSON"></Input>
          </FormItem>
          <FormItem label="跳转JSON" prop="jumpJson">
            <Input type="textarea" rows="5" v-model.trim="formData.jumpJson" style="width:300px" placeholder="请填写跳转JSON"></Input>
          </FormItem>
        </Form>
      </template>
      <template v-slot:toolsbar>
        <Button type="info" style="margin-right:10px" @click="closeModal(false)">取消</Button>
        <Button type="primary" @click="saveData">确定</Button>
      </template>
    </LiefengModal>
  </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";
import LiefengUpload from '@/components/LiefengUpload';

export default {
  components: {LiefengModal, LiefengUpload},

  data() {
    return {
      showCascader: true,
      sysColumnId: [],
      columnList: [],
      titleGrade: '',
      showImage: true,
      controlForm: {
        defaultListIcon: [],
        id:'1',
        formFlag: false,
        timer:'',
        menuTypeList: [{'typeName': '目录', 'typeValue': '1'}, {'typeName': '功能链接', 'typeValue': '2'}, {'typeName': '页面', 'typeValue': '3'}, {'typeName': '按钮', 'typeValue': '4'}],
        linkTargetList: [{'linkName': '本页打开', 'linkValue': '_self'},{'linkName': '新开窗口', 'linkvalue': '_blank'}],
        jumpTypeList: [{'jumpName': '本地', 'jumpValue': 'local'}, {'jumpName': 'APP', 'jumpValue': 'app'}, {'jumpName': '微信小程序', 'jumpValue': 'wechat-app'}, {'jumpName': '网页', 'jumpValue': 'web'}]
      },
      formData: {
        parentId: '',
        type: '',
        name: '',
        url: '',
        icon: '',
        linkTarget: '',
        seq: '',
        third: '1',
        configJson: '',
        enableStatus: '1',
        jumpType: '',
        jumpJson: '',
        sysColumnId: '',
        category: '2',
      },
      formDataValidate: {
        name: [
          {required: true, message: '菜单名称不能为空', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '菜单类型不能为空', trigger: 'change'}
        ],
      },
    }
  },
  //create函数,刚加载页面时调用
  async created() {
    this.getColumnTree();
  },

  methods: {
    getColumnTree(){
      this.$get('/auth/api/auth/pc/systemColumn/selectColumnTree',{
        systemId: this.$route.query.systemId
      }).then(res=>{
        if(res.code == 200){
          this.columnList = res.dataList;
          this.dg(this.columnList);
        }
      })
    },
    dg(children){
      for (var i = 0; i < children.length; i++) {
        children[i]['label'] = children[i].columnName;
        children[i]['value'] = children[i].sysColumnId;
        if (children[i].children) {
          this.dg(children[i].children);
        } else {
          delete(children[i]['children'])
        }
      }
    },
    changeEndTime(time){
      this.formData.endTime = time;
    },
    saveData() {
      this.$refs.form.validate((valid)=> {
            if (valid) {
              this.formData.icon = this.$refs.LiefengUploadIcon.uploadList[0] ?  this.$refs.LiefengUploadIcon.uploadList[0].url : '';
              var isNum = /^\d+$/;
              if (this.formData.seq && !isNum.test(this.formData.seq)) {
                this.$Message.error({
                  content:'请输入正确格式的顺序号(非负整数)',
                  background:true
                })
                return
              }
              if (this.formData.configJson) {
                //校验json
                var configJson = this.formData.configJson
                if (configJson && typeof configJson == 'string') {
                  try {
                    var obj = JSON.parse(configJson);
                    if (typeof obj != 'object' || !obj) {
                      this.$Message["error"]({
                        background: true,
                        content: "配置JSON格式不正确！"
                      });
                      return
                    }
                  } catch (e) {
                    this.$Message["error"]({
                      background: true,
                      content: "配置JSON格式不正确！"
                    });
                    return
                  }
                }
              }
              if (this.formData.jumpJson) {
                //校验json
                var jumpJson = this.formData.jumpJson
                if (jumpJson && typeof jumpJson == 'string') {
                  try {
                    var objt = JSON.parse(jumpJson);
                    if (typeof objt != 'object' || !objt) {
                      this.$Message["error"]({
                        background: true,
                        content: "跳转JSON格式不正确！"
                      });
                      return
                    }
                  } catch (e) {
                    this.$Message["error"]({
                      background: true,
                      content: "跳转JSON格式不正确！"
                    });
                    return
                  }
                }
              }
              console.log(this.sysColumnId)
              this.$post('/auth/api/auth/pc/systemModule/update',{
                ...this.formData,
                sysColumnId: this.sysColumnId.length ? this.sysColumnId[this.sysColumnId.length - 1] : '',
                systemId:this.$route.query.systemId
              }).then(res=>{
                if(res.code == 200){
                  this.$Message.success({
                    content:'更新成功',
                    background:true
                  });
                  this.closeModal();
                  this.$emit('resetBtn')
                }else{
                  this.$Message.error({
                    content:res.desc,
                    background:true
                  })
                  return
                }
              })
            }
          }
      );
    },
    getDetail(systemModuleId) {
      this.$get('/auth/api/auth/pc/systemModule/selectById',{
        systemModuleId
      }).then(res =>{
        if(res.code == 200){
          this.formData = res.data
          if (this.formData.sysColumnId) {
            this.showCascader = false;
            this.sysColumnId.push(this.formData.sysColumnId)
          }
          this.showImage = false
          this.$nextTick(()=>{
            this.showImage = true
            if (this.formData.icon) {
              this.controlForm.defaultListIcon = [{ name: "", url: this.formData.icon }];
            } else {
              this.controlForm.defaultListIcon = []
            }
          })

        }else{
          this.$Message.error({
            content:'获取菜单详情失败',
            background:true
          })
          return
        }
      })
    },
    handleClose(){
      this.showCascader = true
      this.sysColumnId = []
    },
    closeModal(flag){
      if (!flag) {
        //关闭弹窗
        this.controlForm.formFlag = flag;
        //清空表单
        Object.assign(this.$data.formData, this.$options.data().formData);
        //重置表单字段Form
        this.$refs['form'].resetFields();
        this.showImage = false;
        // this.sysColumnId = []
        this.$nextTick(()=>{this.showImage = true;})
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}

textarea
{
  resize:none;
}


</style>